
<template>
  <div class="text-center">
    <div class="body-1 mb-4 mt-2">
      Thanks for providing your information to Stripe.
      <br />
      <span v-if="!showAddBank && !loading">
      To complete the payout set up please add your preferred bank account details by clicking the button below.
      </span>
      <span v-if="isKYCPending && !loading">
      <br />Thanks for adding those details! Finally, we just need some proof of ID to complete the Stripe set up. <br />Please click the link below and follow the on screen instructions.
      </span>
    </div>
    
    <div>
      <v-btn :loading="loading" v-if="!showAddBank" class="mb-4 mr-4" color="primary" :to="{ name: 'promoter.account.edit', params: { tab: 1 } }">Add Bank Details</v-btn>
      <v-btn :loading="loading" v-if="!isKYCPending && showAddBank" class="mb-4 mr-4" color="primary" :to="{ name: 'promoter.account.edit', params: { tab: 1 } }">Return to Payments</v-btn>
      <v-btn :loading="loading" class="mb-4" outlined color="primary" :href="redirectUrl" target="_blank">Update KYC</v-btn>
    </div>
  </div>
</template>
<script>
import { PaymentService, EventService, UserService } from '@services';
export default {
  name: 'StripeReturn',
  data() {
    return {
      showAddBank: true,
      redirectUrl: '',
      loading: false,
      isKYCPending: false,
    };
  },
  created() {
    if(this.$user === null) {
      this.$next('/403');
      return;
    }
    if(this.$user.role !== "promoter") {
      this.$next('/403');
    }
    this.checkStripeKYC();
  },
  methods: {
    async checkStripeKYC() {
      this.loading = true;
      let user = await UserService.me();
      if (user && user.role === 'promoter' && user.stripe && user.stripe.accountId) {
        const striperesponse = await PaymentService.getAccountInfo(user.stripe.accountId);
        const stripeLinkresponse = await PaymentService.getStripeLink(user.stripe.accountId);
        this.showAddBank = striperesponse.data.external_accounts.data.length > 0 ? true : false;
        console.log(striperesponse.data.external_accounts, this.showAddBank, typeof striperesponse.data.legal_entity.verification.details_code, typeof null)
        this.loading = false;
        if(striperesponse.data && striperesponse.data.legal_entity && striperesponse.data.legal_entity.verification && striperesponse.data.legal_entity.verification.status === "unverified" && striperesponse.data.legal_entity.dob.day !== null) {
          this.isKYCPending = true;
        }  else if(striperesponse.data && striperesponse.data.legal_entity && striperesponse.data.legal_entity.verification && striperesponse.data.legal_entity.verification.status === "pending") {
          this.isKYCPending = true;
        }
        this.redirectUrl = stripeLinkresponse.url;
      }
    }
  }
};
</script>